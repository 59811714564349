import { Box } from '@mui/material';
import './resizeSplitScreen.css';
import { useState, useEffect, useCallback } from 'react';

type ResizeSplitScreenProps = {
  width: number;
  widthProtectionDiagram: number;
  setWidthProtectionDiagram: (data: any) => void;
};

const ResizeSplitScreen = ((props: ResizeSplitScreenProps) => {
  const { width, widthProtectionDiagram, setWidthProtectionDiagram } = props;
  
  const MIN_WIDTH = 128;
  
  const [resizeStartPosition, setResizeStartPosition] = useState<number>(0);
  const [maxWidthProtectionDiagram, setMaxWidthProtectionDiagram] = useState(window.innerWidth - MIN_WIDTH*2);
  const [isDragging, setIsDragging] = useState(false);
  
  useEffect(() => {
    setMaxWidthProtectionDiagram(window.innerWidth - MIN_WIDTH*2)
  }, [window.innerWidth])

  const calcNewWidthProtectionDiagram = (deltaX: number) => {
    let newWidth = widthProtectionDiagram + deltaX;
    if (newWidth < MIN_WIDTH) newWidth = MIN_WIDTH;
    if (newWidth > maxWidthProtectionDiagram) newWidth = maxWidthProtectionDiagram;
    return newWidth;
  }

  const handleMousedown = useCallback((e: any) => {
    e.preventDefault();
    setResizeStartPosition(e.clientX);
    setIsDragging(true);
  }, []);
  
  const handleMouseup = useCallback(
    (e: any) => {
      e.preventDefault();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleMousemove = useCallback(
    (e: any) => {
      e.preventDefault();
      if (isDragging) {
        const deltaX = resizeStartPosition - e.clientX;
        let newWidth = calcNewWidthProtectionDiagram(deltaX);
        setWidthProtectionDiagram(newWidth);
      }
    },
    [isDragging, resizeStartPosition]
  );

  const handleTouchstart = useCallback((e: any) => {
    setResizeStartPosition(e.changedTouches[0].clientX);
    setIsDragging(true);
  }, []);
  
  const handleTouchend = useCallback(
    (e: any) => {
      if (e.cancelable){
        if (isDragging) setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleTouchmove = useCallback(
    (e: any) => {
      if (isDragging) {
        const deltaX = resizeStartPosition - e.changedTouches[0].clientX;
        let newWidth = calcNewWidthProtectionDiagram(deltaX);
        setWidthProtectionDiagram(newWidth);
      }
    },
    [isDragging, resizeStartPosition]
  );

  // Listen for mouse events to handle resize by dragging
  useEffect(() => {
    document.addEventListener('mousemove', handleMousemove);
    document.addEventListener('mouseup', handleMouseup);
    document.addEventListener('mouseleave', handleMouseup);
  
    return () => {
      document.removeEventListener('mousemove', handleMousemove);
      document.removeEventListener('mouseup', handleMouseup);
      document.removeEventListener('mouseleave', handleMouseup);
    };
  }, [handleMousemove, handleMouseup]);

  useEffect(() => {
    document.addEventListener('touchmove', handleTouchmove);
    document.addEventListener('touchend', handleTouchend);
    document.addEventListener('touchcancel', handleTouchend);

    return () => {
      document.removeEventListener('touchmove', handleTouchmove);
      document.removeEventListener('touchend', handleTouchend);
      document.removeEventListener('touchcancel', handleTouchend);
    };
  }, [handleTouchmove, handleTouchend]);

  return (
    <Box
      className='custom-col-resize'
      width={width}
      border={'1px solid rgb(116, 116, 116)'}
      onMouseDown={handleMousedown}
      onTouchStart={handleTouchstart}
    ></Box>
  );
});

export default ResizeSplitScreen;